/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  DETAILS: '/issues/:id',
  INSTRUCTION: '/instructions',
  LOGOUT: '/logout',
  LOGIN: '/login',
  VERIFY: '/verify',
  APPROVER_SUCCESS: '/stage/success',
  REPORT_IMAGE_VIEWER: '/viewer/:url',
  DASHBOARD: '/dashboard',
  APPROVER_LINK: '/stage/:id/:token',
  RESPONSE_SUCCESS: '/instructions/:id/success',
  ISSUES: '/issues',
  EQC: '/eqc',
  EQC_DETAILS: '/eqc/:eqcId'
};

export const MODULES = {
  ISSUE: 'Issues',
  EQC: 'EQC'
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const ALL_CONTACTS = 'ALL_CONTACTS';
export const INSTRUCTION_DETAIL = 'INSTRUCTION_DETAIL';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER_KEY = 'USER';
export const TENANT_ID = 'TENANT_ID';
export const ISSUE_FILTER = 'ISSUE_FILTER';
export const EQC_FILTER = 'EQC_FILTER';
export const DEFAULT_PAGE_SIZE = 10;
export const SCROLL_PERCENT = 0.85;
export const SCROLL_CONST = 5;
export const FILE_SIZE_20MB = 20971520;
export const DROPDOWN_LIMIT = 25;
export const TIMEOUT_FOR_OTP_IN_SECONDS = 30;
export const TIMEOUT_FOR_OTP_IN_SECONDS_FOR_WHATSAPP = 60;
export const MAX_FILES_COUNT = 5;
export const MAX_FILES_COUNT_FOR_ISSUE = 1;
export const NUMBER_OF_ROWS_IN_TABLE_SKELETON = 4;
export const NUMBER_OF_SKELETON_CARDS = 3;
export const TIMEOUT_TIME_MS = 3000;
export const POLLING_INTERVAL = 15000;
export const PING_RESOURCE = `/logo192.png`;

export const BREAKPOINTS = {
  mobile: 550,
  tablet: 750,
  desktop: 1000,
  hd: 1200
};

export const TYPES = {
  'Y/N': 'Y/N',
  OPTION: 'OPTION',
  TEXT: 'TEXT',
  NUMERIC: 'NUMERIC'
};

export const TYPES_LABELS = {
  'Y/N': 'Yes/No',
  OPTION: 'Options',
  TEXT: 'Text',
  NUMERIC: 'Numeric'
};

export const EQC_STATUS = {
  PASSED: 'PASSED',
  IN_PROGRESS: 'IN PROGRESS',
  TERMINATE: 'TERMINATE',
  APPROVED: 'APPROVED'
};

export const STAGE_STATUS_LABEL = {
  PASS: 'Pass',
  FAIL: 'Fail',
  APPROVAL_PENDING: 'Approval pending',
  APPROVED: 'Approved',
  REDO: 'Redo',
  SKIP: 'Skip',
  BYPASS: 'Bypass',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected'
};

export const EQC_STAGE_STATUS_KEYS = {
  PASS: 'PASS',
  REDO: 'REDO',
  NOT_DONE: 'NOT_DONE',
  APPROVED: 'APPROVED',
  FAIL: 'FAIL',
  APPROVAL_PENDING: 'APPROVAL_PENDING'
};

export const STAGE_STATUS_CLASSNAME = {
  PASS: 'active-tag',
  FAIL: 'red-tag',
  APPROVAL_PENDING: 'yellow-tag',
  SKIP: 'yellow-tag',
  APPROVED: 'active-tag',
  ACCEPTED: 'active-tag',
  REDO: 'inactive-tag',
  REJECTED: 'inactive-tag',
  NOT_RECORDED: 'inactive-tag',
  BYPASS: 'inactive-tag'
};

export const UOMS = {
  'CU.M': 'Cubic Meter',
  'CU.FT': 'Cubic Feet',
  'SQ.M': 'Square Meter',
  'SQ.FT': 'Square Feet',
  RMT: 'Running Meter',
  RFT: 'Running Feet',
  KG: 'Kilogram',
  NOS: 'Number',
  JOB: 'Job',
  LUMPSUM: 'Lump Sum',
  TONNE: 'Tonne',
  BRASS: 'Brass',
  YARD: 'Yard',
  SQYARD: 'Square Yard',
  MM: 'Milimeter',
  'SQ.MM': 'Square Milimeter',
  QUINTAL: 'Quintal',
  BAG: 'Bag',
  LITER: 'Liter',
  MILILITER: 'Mililiter',
  DFT: 'Dry Film Thickness',
  INCH: 'Inch',
  'SQ.INCH': 'Square Inch',
  FOOT: 'Foot',
  ACRE: 'Acre',
  HECTARE: 'Hectare',
  MINUTES: 'Minutes',
  SECOND: 'Seconds',
  HOURS: 'Hours',
  MANHOURS: 'Man Hours',
  MANDAYS: 'Man Days',
  'DEGREE CELSIUS': 'Degree Celcius'
};

export const EQC_STATUS_CLASSNAME = {
  NOT_STARTED: 'inactive-tag',
  IN_PROGRESS: 'yellow-tag',
  PASSED: 'active-tag',
  TERMINATE: 'red-tag'
};

export const EQC_STATUS_LABEL = {
  IN_PROGRESS: 'In Progress',
  PASSED: 'Passed'
};

export const EQC_STATUS_KEYS = {
  PASSED: 'PASSED',
  IN_PROGRESS: 'IN_PROGRESS',
  TERMINATE: 'TERMINATE'
};

export const QCSTATUS = {
  PASS: 'Pass',
  FAIL: 'Fail',
  SKIP: 'Skip',
  NOT_RECORDED: 'Not_Recorded',
  REDO: 'Redo',
  APPROVED: 'Approved',
  BYPASS: 'Bypass',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected'
};

export const STAGE_STATUS_KEYS = {
  APPROVAL_PENDING: 'APPROVAL_PENDING',
  APPROVED: 'APPROVED',
  FAIL: 'FAIL',
  PASS: 'PASS',
  REDO: 'REDO',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED'
};

export const AGENCY_TYPE = {
  CONSULTANT: 'CONSULTANT',
  CONTRACTOR: 'CONTRACTOR',
  CLIENT: 'CLIENT',
  DEVELOPER: 'DEVELOPER',
  VENDOR: 'VENDOR',
  OTHERS: 'OTHERS',
  LOCAL: 'LOCAL'
};

export const LOGS_TABS_KEYS = {
  ACTIVITY: 'activitylogs',
  INSTRUCTION: 'instructionlogs',
  TODO: 'todo',
  ISSUE: 'issue'
};

export const STAGE_STATUS_TEXT_CLASS = {
  PASS: 'text-success',
  REDO: 'text-danger',
  REJECTED: 'text-danger',
  NOT_DONE: 'text-warning',
  APPROVED: 'text-success',
  ACCEPTED: 'text-success',
  FAIL: 'text-danger',
  APPROVAL_PENDING: 'text-warning',
  BYPASS: 'text-warning',
  SKIP: 'text-secondary'
};

export const STAGE_STATUS = {
  APPROVAL_PENDING: 'APPROVAL_PENDING',
  APPROVED: 'APPROVED',
  FAIL: 'FAIL',
  PASS: 'PASS',
  REDO: 'REDO',
  SKIP: 'SKIP',
  BYPASS: 'BYPASS',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED'
};
/* Date and time */

export const DEFAULTDATEFORMAT = 'DD/MM/YYYY';
export const DATEFORMATWITHDASH = 'DD-MM-YYYY';
export const DEFAULTDATETIMEFORMAT = 'DD/MM/YYYY h:mm a';
export const DATETIMEWITHBRACKET = 'DD/MM/YYYY (h:mm a)';
export const DATETIMEWITHDIVIDE = 'DD/MM/YYYY | h:mm a';
export const DATE_TIME_WITH_AT = 'DD MMM [at] h:mm a';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^[a-z0-9.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/
};

export const ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png'];

export const SUBSCRIPTION_STATUS = {
  OVER_DUE: 'OVER_DUE'
};

export const GA_EVENT = {
  DOWNLOAD_AUDIO: 'download_audio',
  FEATURE_UPGRADE_REQUESTED: 'feature_upgrade_requested',
  OPEN_REQUEST_FEATURE_UPGRADE_MODAl: 'open_request_feature_upgrade_modal',
  DOWNLOAD_INSTRUCTION_FINAL_REPORT: 'download_instruction_final_report',
  OPENED_IMAGE_VIEWER: 'opened_image_viewer',
  REVISIT_YOUR_RESPONSE: 'revisit_your_response',
  REQUESTED_OTP: 'requested_otp',
  REQUESTED_OTP_WITH_FIREBASE: 'requested_otp_with_firebase',
  REQUESTED_OTP_WITH_WHATSAPP: 'requested_otp_with_whatsapp',
  RESEND_OTP: 'resend_otp',
  RESEND_OTP_WITH_FIREBASE: 'resend_otp_with_firebase',
  RESEND_OTP_WITH_WHATSAPP: 'resend_otp_with_whatsapp',
  DOWNLOAD_ISSUE_REPORT: 'download_issue_report',
  ADD_RESPONSE: 'add_response',
  OPENED_AUDIO_MODAL: 'opened_audio_modal',
  APPROVE_EQC: 'approve_eqc',
  REVISIT_APPROVER_COMMENT: 'revisit_approver_comment',
  VIEW_LOCATION: 'view_location',
  VIEW_PROJECT_INSTRUCTION_RENAME_MODAL:
    'view_project_instruction_rename_modal',
  VIEW_DRAWING_PDF: 'view_drawing_pdf',
  VIEW_GENERAL_REMARK_MODAL: 'view_general_remark_modal',
  DOWNLOAD_PROJECT_EQC_DETAILED_REPORT: 'download_project_eqc_detailed_report',
  VIEW_AUDITOR_COMMENT_MODAL: 'View Auditor Comments Modal',
  DOWNLOAD_PROJECT_EQC_FINAL_REPORT: 'download_project_eqc_final_report',
  VIEW_PROJECT_EQC_RENAME_MODAL: 'view_project_eqc_rename_modal',
  DOWNLOAD_PROJECT_EQC_REPORT: 'download_project_eqc_report',
  VIEW_PAUSE_REMARK_MODAL: 'view_pause_remark_modal'
};

export const GA_LABEL = {
  DOWNLOAD_AUDIO: 'Download Audio',
  DOWNLOAD_INSTRUCTION_FINAL_REPORT: 'Download Instruction Final Report',
  OPENED_IMAGE_VIEWER: 'Opened Image Viewer',
  REVISIT_YOUR_RESPONSE: 'Clicked Revisit Your Response',
  REQUESTED_OTP: 'Requested for OTP',
  REQUESTED_OTP_WITH_FIREBASE: 'Requested for OTP Using Firebase',
  REQUESTED_OTP_WITH_WHATSAPP: 'Requested for OTP Using Whatsapp',
  RESEND_OTP: 'Resend OTP',
  RESEND_OTP_WITH_FIREBASE: 'Resend OTP Using Firebase',
  RESEND_OTP_WITH_WHATSAPP: 'Resend OTP Using Whatsapp',
  DOWNLOAD_ISSUE_REPORT: 'Download Issue Report',
  ADD_RESPONSE: 'Added Response in Instruction',
  OPENED_AUDIO_MODAL: 'Opened Audio Player Modal',
  APPROVE_EQC: 'Approved / Redo EQC',
  REVISIT_APPROVER_COMMENT: 'Clicked Revisit Comments Button',
  VIEW_LOCATION: 'Clicked on view location link',
  VIEW_PROJECT_INSTRUCTION_RENAME_MODAL:
    'View Project Instruction Rename Modal',
  VIEW_DRAWING_PDF: 'Clicked on view drawing pdf link',
  VIEW_GENERAL_REMARK_MODAL: 'View General Remark Modal',
  DOWNLOAD_PROJECT_EQC_DETAILED_REPORT: 'Download EQC Detailed Report',
  VIEW_AUDITOR_COMMENT_MODAL: 'View Auditor Comments Modal',
  DOWNLOAD_PROJECT_EQC_FINAL_REPORT: 'Download EQC Final Report',
  VIEW_PROJECT_EQC_RENAME_MODAL: 'View Project Eqc Rename Modal',
  DOWNLOAD_PROJECT_EQC_REPORT: 'Download project eqc report',
  VIEW_PAUSE_REMARK_MODAL: 'View Pause Remark Modal',
  FEATURE_UPGRADE_REQUESTED: 'Feature Upgrade Requested',
  OPEN_REQUEST_FEATURE_UPGRADE_MODAl: 'Open Request Feature Upgrade Modal'
};

export const INS_STATUS_KEYS = {
  RAISED: 'RAISED',
  NOTICE: 'NOTICE',
  RESPONDED: 'RESPONDED',
  REJECTED: 'REJECTED',
  CLOSED: 'CLOSED'
};
export const INS_STATUS_LABEL = {
  RAISED: 'Raised',
  NOTICE: 'Notice',
  RESPONDED: 'Responded',
  REJECTED: 'Rejected',
  CLOSED: 'Closed'
};
export const INS_STATUS_CLASSNAME = {
  RAISED: 'yellow-tag',
  NOTICE: 'active-tag',
  RESPONDED: 'inactive-tag',
  REJECTED: 'red-tag',
  CLOSED: 'active-tag'
};

export const COUNTRY_CODES = {
  IN: '+91',
  AE: '+971',
  NA: '+264',
  TZ: '+255'
};
export const COUNTRY_SORT_NAME = {
  IN: 'IN',
  AE: 'AE',
  NA: 'NA',
  TZ: 'TZ'
};

export const exceptThisSymbols = ['e', 'E', '+', '-', '.'];

export const ELEMENT_IDS = {
  TIME_STAMP: 'time-stamp'
};

export const REPORT_TYPE = {
  PRINTABLE: 'PRINTABLE',
  SHAREABLE: 'SHAREABLE'
};
