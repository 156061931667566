import { Button, Collapse } from 'antd';
import React, { useState } from 'react';
import { useMedia } from 'react-use';
import { FilterWhite } from '../assets/svg';
import { BREAKPOINTS } from '../common/constants';
import SearchComponent from './SearchComponent';

const { Panel } = Collapse;
const CollapsibleFilterWrapper = ({
  searchProps = {},
  children = <div />,
  className,
  isOpen = false,
  filterButtonProps = {
    className: 'filter-btn',
    shape: 'round',
    icon: <FilterWhite />
  }
}) => {
  const { onClick: onFilterButtonClick } = filterButtonProps;
  const { className: searchClassName } = searchProps;
  const [activeKey, setActiveKey] = useState(isOpen ? ['filter'] : []);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const handleClick = () => {
    setActiveKey(activeKey?.length ? [] : ['filter']);
    if (isOpen) {
      // eslint-disable-next-line no-param-reassign
      filterButtonProps.className = 'filter-btn';
    } else {
      // eslint-disable-next-line no-param-reassign
      filterButtonProps.className = 'filter-btn-close';
    }
    if (onFilterButtonClick) onFilterButtonClick();
  };
  return (
    <div className={`collapsible-filter-wrapper ${className || ''}`}>
      {!isDesktopViewport && (
        <div className="d-flex mb-10">
          <SearchComponent
            {...searchProps}
            className={`mr-10 ${searchClassName}`}
          />
          <Button {...filterButtonProps} onClick={handleClick} />
        </div>
      )}
      <Collapse activeKey={isDesktopViewport ? ['filter'] : activeKey}>
        <Panel key="filter">{children}</Panel>
      </Collapse>
    </div>
  );
};

export default CollapsibleFilterWrapper;
