import { useMutation } from '@apollo/client';
import { Button, Form, Row, message } from 'antd';
import clsx from 'clsx';
import firebase from 'firebase/app';
import 'firebase/auth';
import { filter, includes } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../AppContext';
import countryCodeData from '../../assets/countryCode.json';
import { LoginLogo } from '../../assets/svg/login';
import {
  BREAKPOINTS,
  COUNTRY_CODES,
  COUNTRY_SORT_NAME,
  EQC_FILTER,
  EQC_STAGE_STATUS_KEYS,
  GA_EVENT,
  GA_LABEL,
  INS_STATUS_KEYS,
  ISSUE_FILTER,
  ROUTES,
  TIMEOUT_FOR_OTP_IN_SECONDS,
  TIMEOUT_FOR_OTP_IN_SECONDS_FOR_WHATSAPP
} from '../../common/constants';
import { Event } from '../../common/trackEvents';
import { formValidatorRules } from '../../common/utils';
import { auth } from '../../firebase';
import {
  GENERATE_OTP,
  LOGIN_WITH_FIREBASE,
  LOGIN_WITH_OTP,
  LOGIN_WITH_WHATSAPP
} from './graphql/Mutations';

const { required, number } = formValidatorRules;

const Verify = () => {
  const [isResent, setIsResent] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const { initializeAuth } = useContext(AppContext);
  const history = useHistory();
  const isMobileViewport = useMedia(`(max-width: ${BREAKPOINTS.mobile}px)`);
  const phone = history?.location?.state?.phone;
  const countryCode = history?.location?.state?.countryCode;
  // eslint-disable-next-line no-undef
  let { confirmationResult } = window;
  const pathname = history?.location?.state?.pathname;
  const userEmail = history?.location?.state?.userEmail;
  if (!phone || !countryCode) {
    history.replace(ROUTES.LOGIN);
  }
  const [form] = Form.useForm();
  const handleVerificationSuccess = (token) => {
    initializeAuth(token);
    // eslint-disable-next-line no-undef
    localStorage.setItem(
      ISSUE_FILTER,
      JSON.stringify([INS_STATUS_KEYS?.RAISED, INS_STATUS_KEYS?.REJECTED])
    );
    // eslint-disable-next-line no-undef
    localStorage.setItem(EQC_FILTER, EQC_STAGE_STATUS_KEYS?.APPROVAL_PENDING);
    if (pathname && pathname !== ROUTES.LOGOUT) {
      history.push(pathname);
    }
  };
  const [loginWithOTP, { loading }] = useMutation(LOGIN_WITH_OTP, {
    onError() {},
    onCompleted(res) {
      handleVerificationSuccess(res?.loginWithOTP?.token);
    }
  });
  const [generateOtp] = useMutation(GENERATE_OTP, {
    onError() {},
    onCompleted() {
      setResendLoading(false);
      setIsResent(true);
      Event(GA_EVENT.RESEND_OTP, {
        label: GA_LABEL.RESEND_OTP,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        phone,
        countryCode,
        email: userEmail
      });
    }
  });
  const [loginWithFirebase] = useMutation(LOGIN_WITH_FIREBASE, {
    onError() {
      setResendLoading(false);
    },
    onCompleted(res) {
      handleVerificationSuccess(res?.loginWithFirebase?.token);
      setIsLoading(false);
      Event(GA_EVENT.REQUESTED_OTP_WITH_FIREBASE, {
        label: GA_LABEL.REQUESTED_OTP_WITH_FIREBASE,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        phone,
        countryCode,
        email: userEmail
      });
    }
  });

  const [loginWithWhatsApp] = useMutation(LOGIN_WITH_WHATSAPP, {
    onError() {
      setIsLoading(false);
    },
    onCompleted(res) {
      handleVerificationSuccess(res?.loginWithWhatsApp?.token);
      setIsLoading(false);
      Event(GA_EVENT.REQUESTED_OTP_WITH_WHATSAPP, {
        label: GA_LABEL.REQUESTED_OTP_WITH_WHATSAPP,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        phone,
        countryCode,
        email: userEmail
      });
    }
  });

  useEffect(() => {
    if (!includes([COUNTRY_CODES.IN, COUNTRY_CODES.AE], countryCode)) {
      setTimeLeft(TIMEOUT_FOR_OTP_IN_SECONDS_FOR_WHATSAPP);
      setIsResent(true);
    }
  }, [countryCode]);

  useEffect(() => {
    let intervalRef;
    if (isResent) {
      intervalRef = setInterval(() => {
        setTimeLeft((currentTime) => {
          if (currentTime - 1 === 0) {
            setIsResent(false);
          }
          return currentTime - 1;
        });
      }, 1000);
    } else {
      clearInterval(intervalRef);
    }
    return () => {
      clearInterval(intervalRef);
    };
  }, [isResent]);

  const onFinish = async ({ otp }) => {
    if (COUNTRY_CODES?.IN === countryCode) {
      loginWithOTP({
        variables: {
          data: {
            phoneNo: phone,
            country: COUNTRY_SORT_NAME.IN,
            otp: parseInt(otp, 10)
          }
        }
      });
    } else {
      setIsLoading(true);
      const countryShortFoam = filter(
        countryCodeData,
        (record) => record?.dialCode === countryCode
      )?.[0]?.countryCode?.toUpperCase();
      if (countryCode === COUNTRY_CODES.AE) {
        confirmationResult
          .confirm(otp)
          .then(async () => {
            auth?.currentUser?.getIdToken().then(async (res) => {
              await loginWithFirebase({
                variables: {
                  data: {
                    firebaseToken: res,
                    country: countryShortFoam
                  }
                }
              });
            });
          })
          .catch((error) => {
            setIsLoading(false);
            message.destroy();
            message.error(
              error?.code === 'auth/code-expired'
                ? 'OTP Expired'
                : 'Invalid OTP'
            );
            // eslint-disable-next-line no-console
            console.log('firebase verify otp error', error);
          });
      } else {
        // eslint-disable-next-line no-undef
        window.verifyOtp(
          parseInt(otp, 10),
          (data) => {
            loginWithWhatsApp({
              variables: {
                data: {
                  accessToken: data?.message,
                  country: countryShortFoam
                }
              }
            });
          },
          (error) => {
            setIsLoading(false);
            message.destroy();
            message.error(error.message);
          }
        );
      }
    }
  };
  const handleResend = () => {
    setResendLoading(true);
    if (COUNTRY_CODES?.IN === countryCode) {
      setTimeLeft(TIMEOUT_FOR_OTP_IN_SECONDS);
      generateOtp({
        variables: {
          data: {
            phoneNo: `${COUNTRY_CODES?.IN}${phone}`,
            country: COUNTRY_SORT_NAME.IN
          }
        }
      });
    } else if (COUNTRY_CODES?.AE === countryCode) {
      setTimeLeft(TIMEOUT_FOR_OTP_IN_SECONDS);
      auth
        .signInWithPhoneNumber(
          `${countryCode}${phone}`,
          // eslint-disable-next-line no-undef
          window.recaptchaVerifier
        )
        .then(function (result) {
          setResendLoading(false);
          setIsResent(true);
          Event(GA_EVENT.RESEND_OTP_WITH_FIREBASE, {
            label: GA_LABEL.RESEND_OTP_WITH_FIREBASE,
            // eslint-disable-next-line no-undef
            pathname: window?.location?.href,
            phone,
            countryCode,
            email: userEmail
          });
          // eslint-disable-next-line no-undef
          confirmationResult = result;
          message.success('Otp sent successfully!');
        })
        .catch(function (error) {
          setResendLoading(false);
          message.destroy();
          message.error('Something went wrong!');
          // eslint-disable-next-line no-console
          console.log('firebase resend otp error', error);
        });
    } else {
      setTimeLeft(TIMEOUT_FOR_OTP_IN_SECONDS_FOR_WHATSAPP);
      // eslint-disable-next-line no-undef
      window.retryOtp(
        '12',
        () => {
          Event(GA_EVENT.RESEND_OTP_WITH_WHATSAPP, {
            label: GA_LABEL.RESEND_OTP_WITH_WHATSAPP,
            // eslint-disable-next-line no-undef
            pathname: window?.location?.href,
            phone,
            countryCode,
            email: userEmail
          });
          setResendLoading(false);
          setIsResent(true);
        },
        (error) => {
          setResendLoading(false);
          message.destroy();
          message.error(error.message);
        }
      );
    }
  };
  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible'
      }
    );
  }, []);
  return (
    <div className="gx-login-container">
      <div id="recaptcha-container" className="d-none" />
      <div className="login-logo">
        <LoginLogo width="200" height="60" />
      </div>
      <div className="gx-login-content">
        <div className="card-body">
          <h1>Verify your phone</h1>
          <p>
            Enter OTP sent to {countryCode}
            {phone}
          </p>
          <Form
            layout="vertical"
            name="Login"
            onFinish={onFinish}
            className="gx-login-form gx-form-row0"
            form={form}
          >
            <Form.Item
              name="otp"
              rules={[required, number]}
              className="otp-input"
            >
              <OtpInput
                numInputs={6}
                onChange={() => {}}
                className="otp-input"
                isInputNum
                shouldAutoFocus
              />
            </Form.Item>
            <Row>
              <Button
                type="primary"
                htmlType="submit"
                shape="round"
                className="mb-25"
                loading={loading || isLoading}
              >
                Login
              </Button>
            </Row>
          </Form>
          <div className={clsx('d-flex', isMobileViewport && 'flex-vertical')}>
            {isResent ? (
              <div className="text-secondary mr-5">
                Send again in {timeLeft} seconds
              </div>
            ) : (
              <div className={clsx('text-secondary d-flex mr-5')}>
                Didn’t receive OTP ?
                <div
                  className="text-primary pointer ml-5"
                  role="button"
                  onClick={resendLoading ? undefined : handleResend}
                  tabIndex={0}
                >
                  {resendLoading ? 'Sending...' : 'Send Again'}
                </div>
                {!resendLoading && (
                  <div className="text-secondary ml-5">or</div>
                )}
              </div>
            )}
            {!resendLoading && (
              <>
                <div
                  className="text-primary pointer"
                  role="button"
                  tabIndex={0}
                  onClick={() => history.push(ROUTES.MAIN)}
                >
                  Change Number
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verify;
