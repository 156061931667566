import { useQuery } from '@apollo/client';
import { Avatar, Segmented } from 'antd';
import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { MODULES, ROUTES } from '../../../common/constants';
import {
  GET_AGENCY_PENDING_EQC_APPROVAL_LOG_COUNT,
  GET_INSTRUCTION_LIST
} from '../../../modules/dashboard/graphql/Queries';

const initialProjectFilter = {
  skip: 0,
  limit: 10,
  sortBy: { field: 'createdAt', order: 'DESC' }
};

const MobileSubHeader = ({ location: { pathname } }) => {
  const history = useHistory();
  const { data: instructionList } = useQuery(GET_INSTRUCTION_LIST, {
    variables: { filter: initialProjectFilter },
    fetchPolicy: 'network-only'
  });
  const { data } = useQuery(GET_AGENCY_PENDING_EQC_APPROVAL_LOG_COUNT, {
    fetchPolicy: 'network-only'
  });
  return (
    <div className="d-flex align-center justify-center mobile-sub-header">
      <Segmented
        block
        className="width-percent-100 mb-16"
        options={[
          {
            label: (
              <>
                {MODULES.EQC}
                {data?.getAgencyPendingEqcApprovalLogCount?.total > 0 && (
                  <Avatar className="todo-count" size={22}>
                    {data?.getAgencyPendingEqcApprovalLogCount?.total}
                  </Avatar>
                )}
              </>
            ),
            value: ROUTES.EQC
          },
          {
            label: (
              <>
                {MODULES.ISSUE}
                {instructionList?.contactInstructionList
                  ?.instructionActionableCount > 0 && (
                  <Avatar className="todo-count" size={22}>
                    {
                      instructionList?.contactInstructionList
                        ?.instructionActionableCount
                    }
                  </Avatar>
                )}
              </>
            ),
            value: ROUTES.ISSUES
          }
        ]}
        onChange={(e) => history.push(e)}
        value={`/${pathname.split('/')[1]}`}
      />
    </div>
  );
};
export default withRouter(MobileSubHeader);
