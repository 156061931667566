import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const ADD_RESPONSE = gql`
  mutation respondInstruction(
    $instructionId: ID!
    $token: String
    $data: RespondInstructionInput!
  ) {
    respondInstruction(
      instructionId: $instructionId
      token: $token
      data: $data
    ) {
      status
      message
    }
  }
`;
