import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Modal } from 'antd';
import { filter } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../AppContext';
import { RightIcon, WrongIcon } from '../assets/svg';
import { GA_EVENT, GA_LABEL } from '../common/constants';
import { Event } from '../common/trackEvents';
import { GET_LOGGED_IN_USER } from '../modules/auth/graphql/Queries';
import { REQUEST_FEATURE_UPGRADE } from './graphql/Mutation';

const UpgradeModal = ({
  showModal,
  setShowModal,
  isAlreadyRequested = false,
  projectId,
  featureKey,
  tenantId,
  token,
  refetch
}) => {
  const [requestSent, setRequestSent] = useState(false);
  const handleCancel = () => {
    if (requestSent && token) {
      refetch();
    }
    setShowModal(false);
  };

  const { initializeAuth, dispatch } = useContext(AppContext);
  const [getUserProfile] = useLazyQuery(GET_LOGGED_IN_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      initializeAuth(
        '',
        filter(
          res?.getLoggedInContact,
          (val) => val?.tenantId === tenantId
        )?.[0]
      );
      dispatch({
        type: 'SET_All_CONTACTS',
        data: JSON.stringify(res?.getLoggedInContact)
      });
    },
    onError: () => {}
  });

  useEffect(() => {
    if (!requestSent) {
      Event(GA_EVENT.OPEN_REQUEST_FEATURE_UPGRADE_MODAl, {
        label: GA_LABEL.OPEN_REQUEST_FEATURE_UPGRADE_MODAl,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: projectId,
        tenant_id: tenantId,
        is_already_sent: isAlreadyRequested,
        feature_key: featureKey
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [requestFeatureUpgrade, { loading }] = useMutation(
    REQUEST_FEATURE_UPGRADE,
    {
      onCompleted() {
        setRequestSent(true);
        if (!token) {
          getUserProfile();
        }
        Event(GA_EVENT.FEATURE_UPGRADE_REQUESTED, {
          label: GA_LABEL.FEATURE_UPGRADE_REQUESTED,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          tenant_id: tenantId,
          feature_key: featureKey
        });
      },
      onError() {}
    }
  );

  const handleClick = () => {
    requestFeatureUpgrade({
      variables: {
        projectId,
        featureKey,
        token
      }
    });
  };
  return (
    <div id="upgrade-modal">
      <Modal
        maskClosable={false}
        centered
        visible={showModal}
        footer={null}
        width={320}
        onCancel={handleCancel}
        closeIcon={<WrongIcon />}
        getContainer={() =>
          // eslint-disable-next-line no-undef
          document.getElementById('upgrade-modal')
        }
      >
        <div>
          <h2>{isAlreadyRequested ? `Upgrade Require` : `Upgrade`}</h2>
          <p>
            {isAlreadyRequested
              ? `Request already sent`
              : `Your current plan has restriction and requires upgrade.`}
          </p>
          {requestSent ? (
            <div className="text-success d-flex align-center">
              Request Sent <RightIcon className="ml-5" height={15} width={15} />
            </div>
          ) : (
            <div className="form-buttons">
              <Button
                shape="round"
                type="primary"
                className="save-button"
                onClick={() => {
                  return isAlreadyRequested ? handleCancel() : handleClick();
                }}
                disabled={loading}
                loading={loading}
              >
                {isAlreadyRequested ? `Ok` : `Request Upgrade`}
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default UpgradeModal;
