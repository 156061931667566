import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export

export const REFRESH_TOKEN = gql`
  query refreshToken($data: RefreshTokenInput) {
    refreshToken(data: $data) {
      token
      refreshToken
    }
  }
`;

export const VERIFY_PHONE_NUMBER = gql`
  query verifyPhoneNumber($data: OTPData!) {
    verifyPhoneNumber(data: $data) {
      status
      message
    }
  }
`;

export const GET_URL = gql`
  query getSignedPutUrl($data: SignedUrlDataInput) {
    getSignedPutUrl(data: $data) {
      fileName
      signedUrl
      getUrl
    }
  }
`;

export const GET_LOGGED_IN_USER = gql`
  query getLoggedInContact {
    getLoggedInContact {
      id
      name
      email
      phoneNo
      tenantId
      tenant {
        inspectionAssetLimit
        instructionAssetLimit
        featureApprovalRequestConfig {
          inspectionAssetLimitRequestSent
          instructionAssetLimitRequestSent
        }
      }
    }
  }
`;
