import { Button, Col, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation, useMedia } from 'react-use';
import { SuccessIcon } from '../../../../assets/svg';
import {
  BREAKPOINTS,
  DEFAULTDATETIMEFORMAT,
  GA_EVENT,
  GA_LABEL,
  ROUTES
} from '../../../../common/constants';
import { Event } from '../../../../common/trackEvents';
import EllipsisText from '../../../../components/EllipsisText';

const ResponseSuccess = ({ token }) => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const location = useLocation();

  return (
    <div
      className={
        isDesktopViewport
          ? 'success-container d-flex'
          : 'success-container d-grid'
      }
    >
      <Row className="success-container-content">
        <Col>
          <center>
            <h1>Your response has been sent for review</h1>
          </center>
          <center>
            <SuccessIcon />
            <div>
              <h2 className="mb-40">
                <EllipsisText
                  text={`You have given response on ${moment().format(
                    DEFAULTDATETIMEFORMAT
                  )} for ${location?.state?.state?.name}.`}
                />
              </h2>
              <Button
                shape="round"
                type="primary"
                className="mb-20"
                onClick={() => {
                  Event(GA_EVENT.REVISIT_YOUR_RESPONSE, {
                    label: GA_LABEL.REVISIT_YOUR_RESPONSE,
                    // eslint-disable-next-line no-undef
                    pathname: window?.location?.href
                  });
                  // eslint-disable-next-line no-undef
                  window.history.go(-1);
                }}
              >
                Revisit Your Response
              </Button>
            </div>
            <Link to={token ? ROUTES.LOGIN : ROUTES.ISSUES}>
              Go To Issue Dashboard
            </Link>
          </center>
        </Col>
      </Row>
    </div>
  );
};

export default ResponseSuccess;
