import { Image, Space } from 'antd';
import { isEmpty, map } from 'lodash';
import moment from 'moment-timezone';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../../AppContext';
import { AudioIcon } from '../../../../assets/svg';
import {
  DATETIMEWITHBRACKET,
  GA_EVENT,
  GA_LABEL
} from '../../../../common/constants';
import { Event } from '../../../../common/trackEvents';
import EllipsisText from '../../../../components/EllipsisText';

const PreviousDetailsCard = ({
  data,
  setAudios = () => {},
  setShowAudioModal = () => {},
  showAudioColumn = true
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [previewImageKey, setPreviewImageKey] = useState('');
  const { getInstructionDetail } = useContext(AppContext);
  const instructionData = getInstructionDetail();
  return data && !isEmpty(data) ? (
    map(data, (details, index) => (
      <div className="previous-details-card-wrapper mt-20">
        <div>
          <div className="d-flex align-center justify-between mb-15">
            <div className="d-flex">
              <h5>
                {`${data?.length - index}. ${
                  details?.rejector?.name ||
                  details?.responder?.name ||
                  details?.modifier?.name ||
                  ''
                } - ${
                  details?.createdAt &&
                  moment(details?.createdAt)
                    .tz(instructionData?.project?.timeZone)
                    .format(DATETIMEWITHBRACKET)
                }`}
              </h5>
            </div>
          </div>
          <EllipsisText text={details?.remark || '-'} />
          <div className="d-flex justify-between align-center">
            {details?.photos?.length > 0 && (
              <Space direction="vertical" size={12} className="mt-12">
                <div>
                  <Image.PreviewGroup
                    preview={{
                      visible: details?.id === previewImageKey && isVisible,
                      onVisibleChange: (visible) => {
                        setIsVisible(visible);
                        if (visible) {
                          Event(GA_EVENT.OPENED_IMAGE_VIEWER, {
                            label: GA_LABEL.OPENED_IMAGE_VIEWER,
                            // eslint-disable-next-line no-undef
                            pathname: window?.location?.href
                          });
                        }
                      }
                    }}
                  >
                    <Space className="image-preview-wrapper issue-images">
                      {React.Children.map(details?.photos, (photo) => (
                        <Image
                          src={photo}
                          alt="logo"
                          height="64px"
                          width="64px"
                          onClick={() => setPreviewImageKey(details?.id)}
                        />
                      ))}
                      {details?.photos?.length > 1 && (
                        <div
                          className="extra-count pointer"
                          onClick={() => {
                            setIsVisible(true);
                            setPreviewImageKey(details?.id);
                          }}
                        >
                          {`+${details?.photos?.length - 1}`}
                        </div>
                      )}
                    </Space>
                  </Image.PreviewGroup>
                </div>
              </Space>
            )}

            {showAudioColumn && details?.audios?.length > 0 && (
              <Space direction="vertical" size={12} className="mt-12">
                <div>
                  <div
                    className="audio-player-wrapper issue-audio"
                    onClick={() => {
                      setAudios(details?.audios);
                      setShowAudioModal(true);
                      Event(GA_EVENT.DOWNLOAD_AUDIO, {
                        label: GA_LABEL.DOWNLOAD_AUDIO,
                        // eslint-disable-next-line no-undef
                        pathname: window?.location?.href
                      });
                    }}
                  >
                    <AudioIcon />
                    {details?.audios?.length > 1 && (
                      <div className="extra-count">{`+${
                        details?.audios?.length - 1
                      }`}</div>
                    )}
                  </div>
                </div>
              </Space>
            )}
          </div>
        </div>
      </div>
    ))
  ) : (
    <div className="previous-details-card-wrapper mt-20 text-secondary fw-medium">
      No previous details found
    </div>
  );
};

export default PreviousDetailsCard;
