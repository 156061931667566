import { Card } from 'antd';
import React from 'react';
import { useMedia } from 'react-use';
import MobileSubHeader from '../../app/components/header/MobileSubHeader';
import { BREAKPOINTS } from '../../common/constants';
import DashboardList from './components/DashboardList';

const Issues = () => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  return (
    <>
      <div className="logs">
        <div className="activity">
          <Card className="project-module">
            {!isDesktopViewport && <MobileSubHeader />}
            <div className="project-header d-flex justify-between">
              {isDesktopViewport && <h1>Issues</h1>}
              <div
                id="add-search-projects"
                className={`${
                  !isDesktopViewport
                    ? 'width-percent-100'
                    : 'd-flex align-center justify-end'
                }`}
              />
            </div>
            <DashboardList />
          </Card>
        </div>
      </div>
    </>
  );
};

export default Issues;
