import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Form, Input, Row, Select, message } from 'antd';
import firebase from 'firebase/app';
import 'firebase/auth';
import { filter, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import countryCodeData from '../../assets/countryCode.json';
import { LoginLogo } from '../../assets/svg/login';
import {
  COUNTRY_CODES,
  COUNTRY_SORT_NAME,
  GA_EVENT,
  GA_LABEL,
  ROUTES,
  exceptThisSymbols
} from '../../common/constants';
import { Event } from '../../common/trackEvents';
import { formValidatorRules } from '../../common/utils';
import { auth } from '../../firebase';
import { GENERATE_OTP } from './graphql/Mutations';
import { VERIFY_PHONE_NUMBER } from './graphql/Queries';

const { required, number } = formValidatorRules;
const { Option } = Select;
const Login = () => {
  const history = useHistory();
  const pathname = history?.location?.state?.from?.pathname;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [generateOtp, { loading }] = useMutation(GENERATE_OTP, {
    onError() {},
    onCompleted() {
      const { phone, countryCode } = form.getFieldsValue(true);
      Event(GA_EVENT.REQUESTED_OTP, {
        label: GA_LABEL.REQUESTED_OTP,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        phone,
        countryCode
      });
      history.push(ROUTES.VERIFY, {
        phone,
        countryCode,
        pathname
      });
    }
  });

  const [verifyPhoneNumber] = useLazyQuery(VERIFY_PHONE_NUMBER, {
    fetchPolicy: 'network-only',
    onError() {
      setIsLoading(false);
    },
    onCompleted() {
      const { phone, countryCode } = form.getFieldsValue(true);
      if (countryCode === COUNTRY_CODES.AE) {
        auth
          .signInWithPhoneNumber(
            `${countryCode}${phone}`,
            // eslint-disable-next-line no-undef
            window.recaptchaVerifier
          )
          .then(function (confirmationResult) {
            // eslint-disable-next-line no-undef
            window.confirmationResult = confirmationResult;
            message.success('Otp sent successfully!');
            setIsLoading(false);
            Event(GA_EVENT.REQUESTED_OTP_WITH_FIREBASE, {
              label: GA_LABEL.REQUESTED_OTP_WITH_FIREBASE,
              // eslint-disable-next-line no-undef
              pathname: window?.location?.href,
              phone,
              countryCode
            });
            history.push(ROUTES.VERIFY, {
              phone,
              countryCode,
              pathname
            });
          })
          .catch(function (error) {
            setIsLoading(false);
            message.destroy();
            message.error('Something went wrong!');
            // eslint-disable-next-line no-console
            console.log('firebase send otp error', error);
          });
      } else {
        // eslint-disable-next-line no-undef
        window.sendOtp(
          `${countryCode?.replace('+', '')}${phone}`,
          () => {
            message.success('Otp sent successfully!');
            setIsLoading(false);
            Event(GA_EVENT.REQUESTED_OTP_WITH_WHATSAPP, {
              label: GA_LABEL.REQUESTED_OTP_WITH_WHATSAPP,
              // eslint-disable-next-line no-undef
              pathname: window?.location?.href,
              phone,
              countryCode
            });
            history.push(ROUTES.VERIFY, {
              phone,
              countryCode,
              pathname
            });
          },
          (error) => {
            setIsLoading(false);
            message.destroy();
            message.error('Something went wrong!');
            // eslint-disable-next-line no-console
            console.log('whatsapp send otp error', error);
          }
        );
      }
    }
  });

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible'
      }
    );
  }, []);

  const onFinish = async ({ phone, countryCode }) => {
    if (countryCode === COUNTRY_CODES?.IN) {
      generateOtp({
        variables: {
          data: {
            country: COUNTRY_SORT_NAME.IN,
            phoneNo: `${countryCode}${phone}`
          }
        }
      });
    } else {
      const countryShortFoam = filter(
        countryCodeData,
        (record) => record?.dialCode === countryCode
      )?.[0]?.countryCode?.toUpperCase();
      setIsLoading(true);
      verifyPhoneNumber({
        variables: {
          data: {
            phoneNo: `${countryCode}${phone}`,
            country: countryShortFoam
          }
        }
      });
    }
  };

  return (
    <div className="gx-login-container">
      <div id="recaptcha-container" className="d-none" />
      <div className="login-logo">
        <LoginLogo width="200" height="60" />
      </div>
      <div className="gx-login-content">
        <Card className="card-body" bordered={false}>
          <h1>Login</h1>
          <p>Sign in to your account</p>
          <Form
            layout="vertical"
            name="Login"
            onFinish={onFinish}
            className="gx-login-form gx-form-row0 login-form"
            form={form}
            initialValues={{ countryCode: '+91' }}
          >
            <Form.Item
              name="phone"
              rules={[
                number,
                required,
                {
                  min: 6,
                  max: 15,
                  message: 'Should be a valid contact number'
                }
              ]}
              label="Phone Number"
              className="phone-input"
            >
              <Input
                type="number"
                onKeyDown={(e) =>
                  exceptThisSymbols.includes(e.key) && e.preventDefault()
                }
                addonBefore={
                  <Form.Item name="countryCode" className="country-code-input">
                    <Select showSearch>
                      {map(countryCodeData, (record) => (
                        <Option key={record?.id} value={record?.dialCode}>
                          {record?.dialCode}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                }
                placeholder="Phone Number"
              />
            </Form.Item>
            <Row justify="end">
              <Button
                type="primary"
                htmlType="submit"
                shape="round"
                loading={loading || isLoading}
              >
                Request OTP
              </Button>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default Login;
