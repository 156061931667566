import { Modal } from 'antd';
import React from 'react';
import AddResponseForm from './AddResponseForm';

const AddResponseModal = ({
  showModal,
  setShowModal,
  instructionData,
  token,
  refetch
}) => {
  return (
    <div id="add-response-modal">
      <Modal
        maskClosable={false}
        centered
        visible={showModal}
        closable={false}
        destroyOnClose
        footer={null}
        getContainer={() =>
          // eslint-disable-next-line no-undef
          document.getElementById('add-response-modal')
        }
      >
        <AddResponseForm
          setShowModal={setShowModal}
          showModal={showModal}
          instructionName={instructionData?.name}
          uuid={instructionData?.uuid}
          tenantId={instructionData?.tenant?.id}
          projectId={instructionData?.projectId}
          instructionId={instructionData?.id}
          instructionData={instructionData}
          token={token}
          refetch={refetch}
        />
      </Modal>
    </div>
  );
};

export default AddResponseModal;
