import { Drawer } from 'antd';
import React from 'react';
import InstructionSummary from './InstructionSummary';

function ShowDetailsDrawer({ onClose, open, instructionData }) {
  return (
    <div id="view-details-drawer">
      <Drawer
        title="View Details"
        placement="bottom"
        onClose={onClose}
        open={open}
        height={520}
        getContainer={() =>
          // eslint-disable-next-line no-undef
          document.getElementById('view-details-drawer')
        }
      >
        <InstructionSummary instructionData={instructionData} hideTitle />
      </Drawer>
    </div>
  );
}

export default ShowDetailsDrawer;
