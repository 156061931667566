import { Drawer } from 'antd';
import React from 'react';
import AddResponseForm from './AddResponseForm';

function AddResponseDrawer({ onClose, open, instructionData, token, refetch }) {
  return (
    <div id="add-response-drawer">
      <Drawer
        title="Response"
        placement="bottom"
        onClose={onClose}
        open={open}
        height={520}
        destroyOnClose
        getContainer={() =>
          // eslint-disable-next-line no-undef
          document.getElementById('add-response-drawer')
        }
      >
        <AddResponseForm
          instructionName={instructionData?.name}
          uuid={instructionData?.uuid}
          tenantId={instructionData?.tenant?.id}
          projectId={instructionData?.projectId}
          instructionId={instructionData?.id}
          instructionData={instructionData}
          hideTitle
          token={token}
          refetch={refetch}
        />
      </Drawer>
    </div>
  );
}

export default AddResponseDrawer;
