import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const REQUEST_FEATURE_UPGRADE = gql`
  mutation requestFeatureUpgrade(
    $projectId: ID!
    $featureKey: FeatureKey!
    $token: String
  ) {
    requestFeatureUpgrade(
      projectId: $projectId
      featureKey: $featureKey
      token: $token
    ) {
      status
      message
    }
  }
`;
