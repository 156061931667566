import { Modal } from 'antd';
import moment from 'moment-timezone';
import React from 'react';
import { DEFAULTDATETIMEFORMAT } from '../../../../common/constants';
import CommonTable from '../../../../components/CommonTable';

const InstructionRenameModal = ({
  showModal,
  setShowModal,
  data,
  instructionData
}) => {
  const columns = [
    {
      title: 'Old Name',
      dataIndex: 'oldName'
    },
    {
      title: 'New Name',
      dataIndex: 'newName'
    },
    {
      title: 'Renamed By',
      dataIndex: 'user',
      render: (obj) => obj?.name
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      render: (obj) =>
        moment(obj)
          .tz(instructionData?.project?.timeZone)
          .format(DEFAULTDATETIMEFORMAT)
    }
  ];
  return (
    <Modal
      maskClosable={false}
      visible={showModal}
      onCancel={() => setShowModal(false)}
      footer={false}
      width={720}
    >
      <h2>Instruction Rename History</h2>
      <CommonTable
        dataSource={data}
        columns={columns}
        rowKey={(obj) => obj?.id}
      />
    </Modal>
  );
};
export default InstructionRenameModal;
