import { Button, Modal } from 'antd';
import moment from 'moment-timezone';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  DEFAULTDATETIMEFORMAT,
  ROUTES,
  STAGE_STATUS
} from '../../common/constants';

const ApprovalPopUp = (props) => {
  const { showModal, setShowModal, data } = props;

  const handleOK = () => {
    setShowModal(false);
  };
  const stageDetail = data?.getEqcStageHistoryWithoutAuth;
  const approverName =
    stageDetail?.reviewedBy?.externalApprover?.name ||
    stageDetail?.reviewedBy?.internalApprover?.user?.name;
  return (
    <Modal
      maskClosable={false}
      centered
      visible={showModal}
      closable={false}
      footer={null}
    >
      <h3>
        {data?.getEqcStageHistoryWithoutAuth?.isRfiStage ? 'RFI ' : `EQC `}
        already reviewed
      </h3>
      <div>
        <p className="font-size-16 mb-5">
          {data?.getEqcStageHistoryWithoutAuth?.isRfiStage
            ? `${approverName} already ${
                stageDetail && STAGE_STATUS[stageDetail?.status]
              } this RFI on date ${
                stageDetail &&
                moment(stageDetail?.updatedAt)
                  .tz(data?.getEqcStageHistoryWithoutAuth?.project?.timeZone)
                  .format(DEFAULTDATETIMEFORMAT)
                  .toUpperCase()
              }.`
            : `${approverName} has already done the review and given ${
                stageDetail && STAGE_STATUS[stageDetail?.status]
              } to inspector ${
                stageDetail && stageDetail?.action && stageDetail?.action?.name
              }  on date ${
                stageDetail &&
                moment(stageDetail?.updatedAt)
                  .tz(data?.getEqcStageHistoryWithoutAuth?.project?.timeZone)
                  .format(DEFAULTDATETIMEFORMAT)
                  .toUpperCase()
              }.`}
        </p>
        {stageDetail?.approvalPendingCount > 0 && (
          <div className="mb-5">
            <Link to={ROUTES.TODO} className="text-underline">
              You have {stageDetail?.approvalPendingCount} more approvals
              pending to review
            </Link>
          </div>
        )}
      </div>
      <div className="form-buttons">
        <Button
          shape="round"
          type="primary"
          className="save-button"
          onClick={handleOK}
        >
          Okay
        </Button>
      </div>
    </Modal>
  );
};

export default ApprovalPopUp;
