import { Card } from 'antd';
import React from 'react';
import AddResponseForm from './AddResponseForm';

function AddResponseCard({ instructionData, token, refetch }) {
  return (
    <Card className="response-card">
      <AddResponseForm
        instructionName={instructionData?.name}
        uuid={instructionData?.uuid}
        tenantId={instructionData?.tenant?.id}
        projectId={instructionData?.projectId}
        instructionId={instructionData?.id}
        instructionData={instructionData}
        token={token}
        refetch={refetch}
      />
    </Card>
  );
}

export default AddResponseCard;
