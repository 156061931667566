import { Button, Modal } from 'antd';
import React from 'react';

const DeleteAdditionalCommentModal = ({
  showModal = false,
  setShowModal,
  title,
  name,
  setAdditionalComment
}) => {
  const handleOk = () => {
    setAdditionalComment([]);
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };
  return (
    <div>
      <Modal
        maskClosable={false}
        centered
        closable={false}
        footer={null}
        visible={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <h2>{`Remove ${title}`}</h2>
        <p>Are you sure you want to remove {name}?</p>
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            No, Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            onClick={handleOk}
          >
            Yes, Remove
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteAdditionalCommentModal;
