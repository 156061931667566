import { Card } from 'antd';
import React from 'react';

import { useMedia } from 'react-use';
import MobileSubHeader from '../../../../app/components/header/MobileSubHeader';
import { BREAKPOINTS } from '../../../../common/constants';
import TodoList from '../approval/TodoList';

const Eqc = () => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  return (
    <>
      <div className="logs">
        <Card className="project-module">
          {!isDesktopViewport && <MobileSubHeader />}
          <div className="project-header d-flex justify-between">
            {isDesktopViewport && <h1>EQC</h1>}
            <div
              id="add-search-projects"
              className={`  ${
                !isDesktopViewport
                  ? 'width-percent-100'
                  : 'd-flex align-center justify-end'
              }`}
            />
          </div>
          <TodoList />
        </Card>
      </div>
    </>
  );
};

export default Eqc;
