import { useQuery } from '@apollo/client';
import { Avatar, Layout, Menu } from 'antd';
import React, { useContext } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { LoginLogo } from '../../../assets/svg/login';
import { MODULES, ROUTES } from '../../../common/constants';
import {
  GET_AGENCY_PENDING_EQC_APPROVAL_LOG_COUNT,
  GET_INSTRUCTION_LIST
} from '../../../modules/dashboard/graphql/Queries';
import UserProfile from './UserProfile';

const { Header } = Layout;

const initialProjectFilter = {
  skip: 0,
  limit: 10,
  sortBy: { field: 'createdAt', order: 'DESC' }
};
const AppHeader = ({ location: { pathname } }) => {
  const { getCurrentUser } = useContext(AppContext);
  const { data } = useQuery(GET_AGENCY_PENDING_EQC_APPROVAL_LOG_COUNT, {
    fetchPolicy: 'network-only'
  });
  const { data: instructionList } = useQuery(GET_INSTRUCTION_LIST, {
    variables: { filter: initialProjectFilter },
    fetchPolicy: 'network-only'
  });
  const user = getCurrentUser();
  return (
    <Header>
      <>
        <Link to={ROUTES.EQC}>
          <div className="logo">
            <LoginLogo width="145" height="55" />
          </div>
        </Link>
        <div className="gx-search-bar gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg">
          <div className="header d-flex align-center justify-center">
            <Menu
              theme="lite"
              mode="horizontal"
              selectedKeys={[`/${pathname.split('/')[1]}`]}
              defaultSelectedKeys={[ROUTES.EQC]}
            >
              <Menu.Item key={ROUTES.EQC} className="d-flex align-center">
                <NavLink to={ROUTES.EQC}>
                  {MODULES.EQC}
                  {data?.getAgencyPendingEqcApprovalLogCount?.total > 0 && (
                    <Avatar className="todo-count" size={25}>
                      {data?.getAgencyPendingEqcApprovalLogCount?.total}
                    </Avatar>
                  )}
                </NavLink>
              </Menu.Item>
              <Menu.Item key={ROUTES.ISSUES} className="d-flex align-center">
                <NavLink to={ROUTES.ISSUES}>
                  {MODULES.ISSUE}
                  {instructionList?.contactInstructionList
                    ?.instructionActionableCount > 0 && (
                    <Avatar className="todo-count" size={25}>
                      {
                        instructionList?.contactInstructionList
                          ?.instructionActionableCount
                      }
                    </Avatar>
                  )}
                </NavLink>
              </Menu.Item>
            </Menu>
          </div>
        </div>
        <div className="header-notification">
          <UserProfile user={user} />
        </div>
      </>
    </Header>
  );
};
export default withRouter(AppHeader);
