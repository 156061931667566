import { useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Alert } from 'antd';
import React, { useContext, useEffect, useMemo } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { AppContext } from './AppContext';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import App from './app/App';
import { ROUTES } from './common/constants';
import { initGA } from './common/trackEvents';
import { useOnlineStatus } from './common/useNetworkDetect';
import LoaderComponent from './components/LoaderComponent';
import history from './historyData';
import ApproverSuccess from './modules/approver/ApproverSuccess';
import Login from './modules/auth/Login';
import Logout from './modules/auth/Logout';
import Verify from './modules/auth/Verify';
import { GET_LOGGED_IN_USER } from './modules/auth/graphql/Queries';
import Details from './modules/dashboard/components/details';
import ResponseSuccess from './modules/dashboard/components/details/ResponseSuccess';

function getQuery() {
  const { search = '' } = history?.location;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(() => new URLSearchParams(search), [search]);
}

const Routes = () => {
  const { initializeAuth, getToken, dispatch } = useContext(AppContext);
  const path = history?.location?.pathname;
  const idToken = getToken();
  const isOnline = useOnlineStatus();
  const query = getQuery()?.get('token');
  const [getUserProfile, { loading }] = useLazyQuery(GET_LOGGED_IN_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      initializeAuth('', res?.getLoggedInContact?.[0]);
      dispatch({
        type: 'SET_All_CONTACTS',
        data: JSON.stringify(res?.getLoggedInContact)
      });
    },
    onError: () => {}
  });
  useEffect(() => {
    if ((path === ROUTES.LOGOUT && idToken) || idToken) {
      getUserProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idToken]);

  useEffect(() => {
    if (process.env.REACT_APP_ENV !== 'local') {
      initGA(process.env.REACT_APP_GA_CONFIG);
    }
    // eslint-disable-next-line no-undef
  }, []);

  if (loading) return <LoaderComponent />;

  const MyFallbackComponent = ({ error, componentStack }) => (
    <div>
      <p>
        <strong>Oops! An error occured!</strong>
      </p>
      <p>Below is the details…</p>
      <p>
        <strong>Error:</strong> {error.toString()}
      </p>
      <p>
        <strong>Stacktrace:</strong> {componentStack}
      </p>
    </div>
  );

  return (
    <>
      {!isOnline && (
        <div className="alert-div">
          <Alert
            message="Please check your network connection and try again."
            type="error"
            banner
          />
        </div>
      )}
      <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
        <Router history={history}>
          <Switch>
            <PublicRoute exact path={ROUTES.LOGIN} component={Login} />
            <PublicRoute exact path={ROUTES.VERIFY} component={Verify} />
            {query && (
              <>
                <Route
                  exact
                  path={ROUTES.DETAILS}
                  render={() => <Details token={query} />}
                />
                <Route
                  exact
                  path={ROUTES.RESPONSE_SUCCESS}
                  render={() => <ResponseSuccess token={query} />}
                />
              </>
            )}
            <PrivateRoute exact path={ROUTES.LOGOUT} component={Logout} />
            <PrivateRoute
              exact
              path={ROUTES.APPROVER_SUCCESS}
              component={ApproverSuccess}
            />
            <PrivateRoute
              exact
              path={ROUTES.RESPONSE_SUCCESS}
              component={ResponseSuccess}
            />

            <PrivateRoute path={ROUTES.MAIN} component={App} />
          </Switch>
        </Router>
      </Sentry.ErrorBoundary>
    </>
  );
};
export default Routes;
