import { useLazyQuery } from '@apollo/client';
import { Empty } from 'antd';
import * as eva from 'eva-icons';
import { isNaN } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { AppContext } from '../../../../../AppContext';
import Error404 from '../../../../../Error404';
import { ForwardArrow } from '../../../../../assets/svg';
import { ROUTES } from '../../../../../common/constants';
import LoaderComponent from '../../../../../components/LoaderComponent';
import { GET_PROJECT_EQC } from '../graphql/Queries';
import EqcStages from './EqcStages';
import EqcSummary from './EqcSummary';

const EqcDetails = () => {
  const { dispatch } = useContext(AppContext);
  const { eqcId } = useParams();
  const history = useHistory();
  const navFilter = history?.location?.state?.navFilter;
  const navPagination = history?.location?.state?.navPagination;
  const eqcFilter = history?.location?.state?.eqcFilter;
  const eqcPagination = history?.location?.state?.eqcPagination;
  const eqcTypeRecord = history?.location?.state?.eqcTypeRecord;
  const userRecord = history?.location?.state?.userRecord;

  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        history.push(history?.location?.pathname, {
          navFilter,
          navPagination,
          eqcFilter,
          eqcPagination,
          eqcTypeRecord,
          userRecord
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const [fetchEqcDataLazyQuery, { data: eqcData, loading }] = useLazyQuery(
    GET_PROJECT_EQC,
    {
      fetchPolicy: 'network-only'
    }
  );

  const fetchEqcData = () => {
    if (!isNaN(Number(eqcId))) {
      fetchEqcDataLazyQuery({
        variables: {
          id: eqcId
        }
      });
    }
  };

  useEffect(() => {
    fetchEqcData();
    return () => {
      dispatch({ type: 'SET_PROJECT_ROLE', data: '' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isNaN(Number(eqcId))) {
    return <Error404 />;
  }
  return loading ? (
    <LoaderComponent />
  ) : (
    <div>
      <div className="project-details">
        <div className="project-details-title justify-between page-header">
          <div className="project-details-title-text d-flex">
            <h1
              className="pointer mr-10 text-primary"
              onClick={() => {
                history.push(ROUTES.EQC, {
                  navFilter,
                  navPagination,
                  eqcFilter,
                  eqcPagination,
                  eqcTypeRecord,
                  userRecord
                });
              }}
            >
              EQC
            </h1>
            <ForwardArrow />
            <div className="h1-typography issue-heading ellipsis-text">
              {eqcData?.getEqc?.eqcName}
            </div>
          </div>
        </div>
        {eqcData?.getEqc ? (
          <div className="eqc-tab-details mobile-card-wrapper">
            <div className="mb-15 ">
              <EqcSummary
                data={eqcData?.getEqc}
                refetch={() => {
                  if (!eqcData?.isAudited) {
                    fetchEqcData();
                  }
                }}
              />
            </div>
            <div>
              <EqcStages data={eqcData?.getEqc} />
            </div>
          </div>
        ) : (
          <Empty />
        )}
      </div>
    </div>
  );
};

export default EqcDetails;
