import { MoreOutlined } from '@ant-design/icons';
import { Button, Divider, Menu, Popover, Space } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import alternateProfile from '../../../assets/images/alternateProfile.svg';
import { BREAKPOINTS, ROUTES } from '../../../common/constants';

const UserProfile = ({ user }) => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const userMenuOptions = (
    <Menu onClick={() => setVisible(false)}>
      {!isDesktopViewport && (
        <>
          <Menu.Item key={ROUTES.LOGOUT}>
            <div className="d-flex justify-center align-center flex-vertical user-profile">
              <img
                src={alternateProfile}
                className="profileImage gx-pointer mr-5 object-fit-contain "
                alt="Avatar"
              />
              <div className="text-secondary user-phone mt-5">
                {user?.phoneNo}
              </div>
            </div>
          </Menu.Item>
          <Divider />
        </>
      )}
      <Menu.Item key={ROUTES.LOGOUT}>
        <Button
          onClick={() => history.push(ROUTES?.LOGOUT)}
          className="width-percent-100"
          type="primary"
        >
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );
  const handleVisibleChange = (value) => {
    setVisible(value);
  };

  return (
    <div className="gx-avatar-row pointer header-right">
      <div className="header-right-menu" />
      <Popover
        className="d-flex flex-row user-profile"
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisibleChange}
        getTooltipContainer={(triggerNode) => triggerNode?.parentNode}
      >
        {isDesktopViewport ? (
          <Space direction="horizontal">
            <div className="text-secondary user-phone">{user?.phoneNo}</div>
            <img
              src={alternateProfile}
              className="profileImage gx-pointer mr-5"
              alt="Avatar"
            />
          </Space>
        ) : (
          <MoreOutlined />
        )}
      </Popover>
    </div>
  );
};

export default UserProfile;
