import { DeleteOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  Popconfirm,
  Row,
  Upload,
  message
} from 'antd';
import clsx from 'clsx';
import { filter, includes, map, uniq, uniqBy } from 'lodash';
import moment from 'moment-timezone';
import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../AppContext';
import { Camera, Gallery } from '../../../../assets/svg';
import {
  ALLOWED_IMAGE_TYPES,
  BREAKPOINTS,
  DATETIMEWITHDIVIDE,
  ELEMENT_IDS,
  FILE_SIZE_20MB,
  GA_EVENT,
  GA_LABEL,
  MAX_FILES_COUNT,
  ROUTES
} from '../../../../common/constants';
import { Event } from '../../../../common/trackEvents';
import {
  addWatermark,
  compressImage,
  dataURLtoFile,
  fileUpload,
  formValidatorRules
} from '../../../../common/utils';
import UpgradeModal from '../../../../components/UpgradeModal';
import { ADD_RESPONSE } from '../../graphql/Mutations';
import { GET_URLS } from '../../graphql/Queries';

const { required } = formValidatorRules;
const getBase64 = (img, callback) => {
  // eslint-disable-next-line no-undef
  const reader = new FileReader();
  reader.readAsDataURL(img);
  reader.addEventListener('load', () => callback(reader?.result));
};
const { TextArea } = Input;
const AddResponseForm = ({
  uuid,
  tenantId,
  projectId,
  setShowModal,
  instructionId,
  instructionName,
  instructionData,
  hideTitle = false,
  token,
  refetch
}) => {
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(false);
  const [fileList, setFileList] = useState([]);
  const history = useHistory();
  const { id } = useParams();
  const { getAllContacts } = useContext(AppContext);
  const [imageUrls, setImageUrls] = useState([]);
  const allContacts = getAllContacts();
  const [showImageSizeError, setShowImageSizeError] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const currentTenantData = filter(
    allContacts,
    (val) => val?.tenantId === tenantId
  )?.[0];
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  const [addResponse] = useMutation(ADD_RESPONSE, {
    onCompleted() {
      Event(GA_EVENT.ADD_RESPONSE, {
        label: GA_LABEL.ADD_RESPONSE,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        instruction_id: id
      });
      history.push(`${ROUTES.INSTRUCTION}/${id}/success`, {
        name: instructionName
      });
    },
    onError() {
      setDisabled(false);
    }
  });

  const submitData = async (urls) => {
    const formValues = form.getFieldsValue(true);
    const promises = map(urls, (imageKey, index) => ({
      type: 'GENERAL_REMARK',
      key: imageKey,
      assetType: 'IMAGE',
      size: fileList[index]?.size,
      isImageCaptured: !fileList[index]?.originFileObj,
      capturedAt: moment()
    }));
    await Promise.all(promises);
    addResponse({
      variables: {
        instructionId: id,
        token,
        data: { remark: formValues?.comment, assets: promises }
      }
    });
  };
  const [fetchSignedUrl] = useLazyQuery(GET_URLS, {
    fetchPolicy: 'network-only',
    onError() {
      setDisabled(false);
    },
    onCompleted: async (response) => {
      const filteredFileList = filter(fileList, 'name');
      const promises = map(
        response?.getSignedPutUrls?.signedUrls,
        async (imageUrl, index) => {
          const callBack = async (image) => {
            const watermarkedImageUrl = await addWatermark(image);
            const file = dataURLtoFile(
              watermarkedImageUrl,
              filteredFileList[index]?.originFileObj?.name || 'response_image'
            );
            await fileUpload(imageUrl, file).catch(() => {
              return message.error('Image upload failed!');
            });
          };
          await compressImage({
            file:
              filteredFileList?.[index]?.originFileObj ||
              filteredFileList?.[index],
            fileType: filteredFileList?.[index]?.type?.split('/')?.[1],
            callBack: callBack
          });
        }
      );
      await Promise.all(promises);
      submitData(response?.getSignedPutUrls?.keys);
    }
  });

  const handleOnChange = (info) => {
    const newFileList = filter(info?.fileList || info, (file) => {
      setShowImageSizeError(false);
      if (file?.size > FILE_SIZE_20MB) {
        setShowImageSizeError(true);
        return false;
      }
      if (!includes(ALLOWED_IMAGE_TYPES, file?.type)) {
        message.destroy();
        message.error('File type should be PNG, JPG, JPEG');
        return false;
      }
      getBase64(file?.originFileObj || file, (images) =>
        setImageUrls((prev) =>
          uniqBy(
            [
              ...prev,
              {
                obj: file?.originFileObj || file,
                imageUrl: images
              }
            ],
            'obj'
          )
        )
      );
      return file;
    });
    setFileList(uniq([...fileList, ...newFileList]));
    form.validateFields();
  };

  const handleUploadImage = async () => {
    setDisabled(true);
    const fileSend = (anotherFileList) => {
      return map(anotherFileList, (file) => ({
        fileName: `instruction/tenant/${tenantId}/project/${projectId}/${
          uuid || `instructionId/${instructionId}`
        }/response/${file?.name}`.replace(/ /g, '_'),
        contentType: file?.type,
        acl: 'private'
      }));
    };
    const filteredFileList = filter(fileList, 'name');
    if (filteredFileList?.length) {
      fetchSignedUrl({
        variables: {
          data: fileSend(filteredFileList)
        }
      });
    } else {
      submitData();
    }
  };

  const onFinish = async () => {
    form
      .validateFields()
      .then(async () => {
        await handleUploadImage();
      })
      .catch();
  };

  const handleCancel = () => {
    form.resetFields();
    setShowImageSizeError(false);
    setFileList([]);
    setImageUrls([]);
    if (setShowModal) {
      setShowModal(false);
    }
  };

  const handleRemove = (fileIndex) => {
    const newFileList = filter(fileList, (data, index) => index !== fileIndex);
    const newImageUrls = filter(
      imageUrls,
      (data, index) => index !== fileIndex
    );
    setImageUrls(newImageUrls);
    setFileList(newFileList);
    form.validateFields();
  };
  return (
    <>
      <h2 hidden={hideTitle}>Response</h2>
      {
        //! giving inline style as need to generate image from this div
      }
      <div style={{ opacity: 0, height: 0 }}>
        <div
          id={ELEMENT_IDS?.TIME_STAMP}
          // this will be used in future again
          // style={{
          //   background: '#000',
          //   color: '#3366ff',
          //   width: 'max-content',
          // }}
          //! this is temporary fix will be removed in future
          style={{
            background: 'transparent',
            color: 'transparent',
            width: 'max-content'
          }}
        >
          {`${moment()
            .tz(instructionData?.project?.timeZone)
            .format(DATETIMEWITHDIVIDE)} - ${
            instructionData?.project?.timeZone
          }`}
        </div>
      </div>
      <div className="d-flex justify-between flex-vertical height-percent-100">
        <Form form={form} layout="vertical">
          <Form.Item
            name="comment"
            label="Comment"
            rules={[
              required,
              {
                max: 1000,
                message: 'Comment cannot be more than 1000 characters'
              }
            ]}
          >
            <TextArea
              autoSize={{ minRows: 4, maxRows: 4 }}
              placeholder="Add comment here"
            />
          </Form.Item>

          <div className="mb-10 text-secondary">
            {instructionData?.descriptionPhoto?.length > 0 && (
              <span className="text-danger mr-5">*</span>
            )}
            Images
          </div>
          <Form.Item
            rules={[
              () => ({
                validator(rule, value) {
                  if (
                    (!value || !fileList?.length > 0) &&
                    instructionData?.descriptionPhoto?.length > 0
                  ) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject('Required');
                  }
                  return Promise.resolve();
                }
              })
            ]}
            name="images"
          >
            <Row justify="center" align="middle" gutter={18}>
              {hideTitle && (
                <Col span={12} className="width-percent-100">
                  <label
                    htmlFor="file-up"
                    className={clsx('file-upload width-percent-100')}
                  >
                    <Camera className="mr-10" /> Click
                    <input
                      id="file-up"
                      type="file"
                      accept="image/*"
                      capture="camera"
                      hidden
                      title=" "
                      onClick={(e) => {
                        if (
                          fileList?.length ===
                          (currentTenantData?.tenant?.instructionAssetLimit ||
                            instructionData?.tenant?.instructionAssetLimit ||
                            MAX_FILES_COUNT)
                        ) {
                          e.preventDefault();
                          setShowUpgradeModal(true);
                        }
                      }}
                      onChange={(info) => {
                        const file = info?.target?.files;
                        if (file?.size > FILE_SIZE_20MB) {
                          setShowImageSizeError(true);
                          return false;
                        }
                        handleOnChange(file);
                      }}
                    />
                  </label>
                </Col>
              )}
              <Col span={isDesktopViewport ? 24 : 12}>
                <Upload
                  maxCount={
                    currentTenantData?.tenant?.instructionAssetLimit ||
                    instructionData?.tenant?.instructionAssetLimit ||
                    MAX_FILES_COUNT
                  }
                  accept=".png,.jpeg,.jpg"
                  multiple
                  listType={isDesktopViewport && 'picture-card'}
                  fileList={fileList}
                  className="width-percent-100"
                  onChange={(info) => {
                    setImageUrls([]);
                    handleOnChange(info);
                  }}
                  beforeUpload={(e, ...rest) => {
                    if (
                      [...rest?.[0], ...fileList]?.length >
                      (currentTenantData?.tenant?.instructionAssetLimit ||
                        instructionData?.tenant?.instructionAssetLimit)
                    ) {
                      setShowUpgradeModal(true);
                    }
                    return false;
                  }}
                  showUploadList={false}
                >
                  <div
                    className="height-percent-100 width-percent-100 d-flex align-center justify-center pointer"
                    onClick={(e) => {
                      if (
                        fileList?.length ===
                        (currentTenantData?.tenant?.instructionAssetLimit ||
                          instructionData?.tenant?.instructionAssetLimit ||
                          MAX_FILES_COUNT)
                      ) {
                        e.stopPropagation();
                        setShowUpgradeModal(true);
                      }
                    }}
                  >
                    {isDesktopViewport ? (
                      <Button type="link" shape="round" className="add-button">
                        Add
                      </Button>
                    ) : (
                      <Button
                        shape="round"
                        className="upload-button"
                        icon={<Gallery className="mr-10" />}
                      >
                        Upload
                      </Button>
                    )}
                  </div>
                </Upload>
              </Col>
            </Row>
          </Form.Item>
          {showImageSizeError && (
            <div className="text-danger font-size-12">
              * Maximum file size is 20MB
            </div>
          )}
          {imageUrls?.length > 0 && (
            <div className="uploaded-images mb-10 mt-10">
              {map(imageUrls, (photo, index) => {
                return (
                  <span
                    className="response-images"
                    onClick={() => {
                      handleRemove(index);
                    }}
                  >
                    <Image key={index} src={photo?.imageUrl} />
                    <DeleteOutlined
                      height="10px"
                      width="10px"
                      className="delete-response-image-button"
                    />
                  </span>
                );
              })}
            </div>
          )}
        </Form>
        <div className="form-buttons">
          {!hideTitle && (
            <Button
              shape="round"
              className="cancel-button"
              onClick={handleCancel}
              disabled={disabled}
            >
              Cancel
            </Button>
          )}
          <Popconfirm
            title="Are you sure to add this response?"
            okText="Yes"
            cancelText="No"
            okButtonProps={{ shape: 'round' }}
            cancelButtonProps={{ shape: 'round' }}
            placement="topRight"
            onConfirm={onFinish}
          >
            <Button
              shape="round"
              className={clsx(
                disabled && !hideTitle ? 'ml-10' : `save-button`,
                hideTitle && 'width-percent-100'
              )}
              disabled={disabled}
              type="primary"
            >
              {disabled ? 'Sending...' : 'Send'}
            </Button>
          </Popconfirm>
        </div>
      </div>
      {showUpgradeModal && (
        <UpgradeModal
          showModal={showUpgradeModal}
          token={token}
          setShowModal={setShowUpgradeModal}
          projectId={projectId}
          featureKey="INSTRUCTION_ASSET_LIMIT"
          tenantId={tenantId}
          refetch={refetch}
          isAlreadyRequested={
            currentTenantData?.tenant?.featureApprovalRequestConfig
              ?.instructionAssetLimitRequestSent ||
            instructionData?.tenant?.featureApprovalRequestConfig
              ?.instructionAssetLimitRequestSent
          }
        />
      )}
    </>
  );
};

export default AddResponseForm;
