import React, { useContext } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { AppContext } from './AppContext';
import { ROUTES } from './common/constants';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { getToken } = useContext(AppContext);
  const idToken = getToken();
  const location = useLocation();
  return (
    <Route
      {...rest}
      render={(props) =>
        !idToken ? (
          <Redirect
            to={{
              pathname: ROUTES.LOGIN,
              state: { from: location }
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};
export default PrivateRoute;
