import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Error404 from '../Error404';
import { ROUTES } from '../common/constants';
import ApproverLink from '../modules/approver/ApproverLink';
import Issues from '../modules/dashboard/Issues';
import Details from '../modules/dashboard/components/details';
import Eqc from '../modules/dashboard/components/eqc/Eqc';
import EqcDetails from '../modules/dashboard/components/eqc/eqcDetails/EqcDetails';

const ContentRoutes = () => {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.scrollTo(0, 0);
  }, []);

  return (
    <Switch>
      <Route exact path={ROUTES.ISSUES} component={Issues} />
      <Route exact path={`${ROUTES.EQC_DETAILS}`} component={EqcDetails} />
      <Route exact path={`${ROUTES.EQC}`} component={Eqc} />
      <Route exact path={ROUTES.DETAILS} component={Details} />
      <Route exact path={ROUTES.APPROVER_LINK} component={ApproverLink} />
      <Route path={ROUTES.MAIN} render={() => <Redirect to={ROUTES?.EQC} />} />
      <Route path="*" exact component={Error404} />
    </Switch>
  );
};

export default ContentRoutes;
