import { Layout } from 'antd';
import React, { useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { LoginLogo } from '../../../assets/svg/login';
import { ROUTES } from '../../../common/constants';
import UserProfile from './UserProfile';

const { Header } = Layout;

const MobileHeader = () => {
  const { getCurrentUser } = useContext(AppContext);
  const user = getCurrentUser();

  return (
    <Header>
      <div className="d-flex mobile-header justify-between align-center width-percent-100 ">
        <div className="d-flex align-center">
          <Link to={ROUTES.EQC}>
            <div className="logo">
              <LoginLogo width="110" height="40" />
            </div>
          </Link>
        </div>
        <div className="header-notification header-notification-mobile flex-vertical">
          <UserProfile user={user} />
        </div>
      </div>
      <div id="nav-bar-dropdown" />
    </Header>
  );
};
export default withRouter(MobileHeader);
